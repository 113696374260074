import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import styled from "@emotion/styled";
import { Typography15, Button, Typography34, Typography24, Typography20 } from "@slid/slid-ips";
import Link from "next/link";
import env from "@/config/env";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useIsMobile } from "@/utils/customHook/customHook";
import { useIsAuthenticated } from "src/utils/customHook/useIsAuthenticated";

const SLID_WEB_SITE_URL = env.end_point_url.slid_web_site;
const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

export default function Custom404() {
  const { t } = useTranslation();
  const [errorState, setErrorState] = useState("PAGE_NOT_FOUND");
  const isAuthenticated = useIsAuthenticated();

  const isMobile = useIsMobile();

  useEffect(() => {
    const { error } = queryString.parse(window.location.search);
    if (error) setErrorState(error);
  }, []);

  const renderErrorText = (error) => {
    switch (error) {
      case "DOCUMENT_NOT_PUBLIC":
        return (
          <ErrorTextWrapper>
            {!isMobile ? (
              <>
                <Typography34 text={t("NoteIsPrivateTitle", { ns: "Error" })} />
                <Typography20 text={t("NoteIsPrivateDesc", { ns: "Error" })} marginTop={"16px"} weight={400} />
              </>
            ) : (
              <>
                <Typography24 text={t("NoteIsPrivateTitle", { ns: "Error" })} />
                <Typography15 text={t("NoteIsPrivateDesc", { ns: "Error" })} marginTop={"16px"} weight={400} />
              </>
            )}
          </ErrorTextWrapper>
        );

      case "DOCUMENT_NOT_FOUND":
        return <>{!isMobile ? <Typography34 text={t("NoteNotFound", { ns: "Error" })} /> : <Typography24 text={t("NoteNotFound", { ns: "Error" })} />}</>;

      default:
        return <>{!isMobile ? <Typography34 text={t("PageNotFound", { ns: "Error" })} /> : <Typography24 text={t("PageNotFound", { ns: "Error" })} />}</>;
    }
  };

  const renderErrorButton = (error) => {
    switch (error) {
      case "DOCUMENT_NOT_FOUND":
        return isAuthenticated ? (
          <Link href={`${SLID_WEB_APP_URL}/docs`} passHref={true}>
            <Button text={t("GoToMyNotes")} appearance={"primary"} size={isMobile ? "medium" : "large"} marginTop={"48px"} />
          </Link>
        ) : (
          <Link href={`${SLID_WEB_SITE_URL}`} passHref={true}>
            <Button text={t("GoToHome")} appearance={"primary"} size={isMobile ? "medium" : "large"} marginTop={"48px"} />
          </Link>
        );
      default:
        return (
          <Link href={`${SLID_WEB_SITE_URL}`} passHref={true}>
            <Button text={t("GoToHome")} appearance={"primary"} size={isMobile ? "medium" : "large"} marginTop={"48px"} />
          </Link>
        );
    }
  };

  return (
    <PageLayout>
      {renderErrorText(errorState)}
      <ImageContainer>
        <WelcomeImage src={`/assets/design/slid_welcome.png`} alt="" layout="fill" className="autoImage" />
      </ImageContainer>
      {renderErrorButton(errorState)}
    </PageLayout>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "Error"])),
    },
  };
}

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  gap: 48px;
  padding: 50px;
`;

const ErrorTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 359px;
  & > span {
    position: unset !important;
    & .autoImage {
      object-fit: contain !important;
      position: relative !important;
      height: auto !important;
    }
  }
`;

const WelcomeImage = styled(Image)``;
